import type { Component } from 'vue';
import HeaderDefault from '~/components/header-modules/HeaderDefault.vue';
import Header3d from '~/components/header-modules/Header3d.vue';
import HeaderBlog from '~/components/header-modules/HeaderBlog.vue';

export const HeaderModuleComponents: Record<string, Component> = {
  HeaderDefaultRecord: HeaderDefault,
  HeaderBlogRecord: HeaderBlog,
  Header3dRecord: Header3d,
};
